import {
  applicationHeader,
  cleanerHeader,
  cleanScrubPadHeader,
  equipmentHeader,
  floorHeader,
} from "../../utils";
import {
  applicationMethodScrubNRecoatContent,
  cleanerScrubNRecoatContent,
  equipmentScrubNRecoatContent,
  floorFinishScrubNRecoatContent,
  scrubbingPadScrubNRecoatContent,
} from "../contents";

export const createScrubRecoatData = (elements, fullDataCurrent) => {
  const scrubRecoatOnly = elements?.map((elm) => {
    const {
      scrubCleaner,
      scrubCleanerScj,
      scrubEquipment,
      scrubEquipmentScj,
      scrubFloor,
      scrubFloorScj,
      scrubMethod,
      scrubMethodScj,
      scrubPad,
      scrubPadScj,
    } = elm;
    return {
      scrubCleaner,
      scrubCleanerScj,
      scrubEquipment,
      scrubEquipmentScj,
      scrubFloor,
      scrubFloorScj,
      scrubMethod,
      scrubMethodScj,
      scrubPad,
      scrubPadScj,
    };
  });

  const equipment = scrubRecoatOnly?.flatMap((i) => i.scrubEquipment);
  const cleaner = scrubRecoatOnly?.flatMap((i) => i.scrubCleaner);
  const floor = scrubRecoatOnly?.flatMap((i) => i.scrubFloor);
  const method = scrubRecoatOnly?.flatMap((i) => i.scrubMethod);
  const pad = scrubRecoatOnly?.flatMap((i) => i.scrubPad);
  const data = [
    {
      headers: equipmentHeader,
      variable: "scrubEquipment",
      original: equipment,
      content: equipment?.map((i) => equipmentScrubNRecoatContent),
      fullData: [
        equipment,
        cleaner,
        floor,
        method,
        pad,
        fullDataCurrent?.[0].fullData,
      ]
        .flat()
        .filter(Boolean),
      equipmentData: cleaner,
    },
    {
      headers: cleanerHeader,
      variable: "scrubCleaner",
      original: cleaner,
      content: cleaner?.map((i) => cleanerScrubNRecoatContent),
      canIgnoreIssaCoverage: true,
    },
    {
      headers: cleanScrubPadHeader,
      variable: "scrubPad",
      original: pad,
      content: pad?.map((i) => scrubbingPadScrubNRecoatContent),
    },
    {
      headers: applicationHeader,
      variable: "scrubMethod",
      original: method,
      content: method?.map((i) => applicationMethodScrubNRecoatContent),
    },
    {
      headers: floorHeader,
      variable: "scrubFloor",
      original: floor,
      content: floor?.map((i) => floorFinishScrubNRecoatContent),
    },
  ];
  return data;
};
