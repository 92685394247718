import { burnishingHeader, equipmentHeader } from "../../utils";
import {
  burnishingPadBurnishingContent,
  equipmentBurnishingContent,
} from "../contents";

export const createBurnishingsData = (elements, fullDataCurrent) => {
  const burnishingOnly = elements?.map((elm) => {
    const {
      burnishingEquipment,
      burnishingEquipmentScj,
      burnishingPad,
      burnishingPadScj,
    } = elm;
    return {
      burnishingEquipment,
      burnishingEquipmentScj,
      burnishingPad,
      burnishingPadScj,
    };
  });
  const equipment = burnishingOnly?.flatMap((i) => i.burnishingEquipment);
  const pad = burnishingOnly?.flatMap((i) => i.burnishingPad);
  const data = [
    {
      headers: equipmentHeader,
      variable: "burnishingEquipment",
      original: equipment,
      content: equipment?.map((i) => equipmentBurnishingContent),
      equipmentData: pad,
      fullData: [equipment, pad, fullDataCurrent?.[0].fullData]
        .flat()
        .filter(Boolean),
    },
    {
      headers: burnishingHeader,
      variable: "burnishingPad",
      original: pad,
      content: pad?.map((i) => burnishingPadBurnishingContent),
      canIgnoreIssaCoverage: true,
    },
  ];
  return data;
};
