//  ------------- NON SCJP ----------------- //

import numbro from "numbro";

// Initial Strip-Out of Old Finish
export const equipmentInstallStrip = [
  { value: null, display: "select", packageKey: "conventionalFloorStripping" },
  { value: "timeYr", display: "normal" },
  { value: null, display: "group" },
  { value: "issa", display: "input" },
  { value: "hoursYr", display: "calculation", operation: "issaHours" },
  { value: "costYr", display: "calculation", operation: "issaCost" },
];

export const strippingPadInstallStrip = [
  { value: null, display: "select", packageKey: "strippingPad" },
  { value: "timeYr", display: "normal" },
  { value: "mpp", display: "group" },
  { value: "shot_size", display: "input" },
  { value: "padsYr", display: "calculation", operation: "padsNGalsHoursEz" },
  { value: "costYr", display: "calculation", operation: "padsNGalsCostEz" },
];
export const conditioninNPolisingPadInstallStrip = [
  { value: null, display: "select", packageKey: "conditionAndPolishPad" },
  { value: "timeYr", display: "normal" },
  { value: "mpp", display: "group" },
  { value: "shot_size", display: "input" },
  { value: "padsYr", display: "calculation", operation: "padsNGalsHoursEz" },
  { value: "costYr", display: "calculation", operation: "padsNGalsCostEz" },
];

// Install Ez Care Floor Coating
export const applyFloorCoating = [
  { value: null, display: "product", packageKey: "ezCareFloorC" },
  { value: "mpp", display: "group" },
  { value: "cost_per_gal", display: "calculation", operation: "costGal" },
  { value: "issa_coverage", display: "input" },
  { value: "galYr", display: "calculation", operation: "padsNGalsHoursEz" },
  { value: "costYr", display: "calculation", operation: "padsNGalsCostEz" },
];
export const installCoatingFloorCoating = [
  { value: null, display: "normal" },
  { value: "timeYr", display: "normal" },
  { value: "coats", display: "input" },
  { value: "shot_size", display: "normal" },
  { value: "hoursYr", display: "calculation", operation: "coatsHours" },
  { value: "costYr", display: "normal", operation: "coatsCost" },
];

// Maintain

export const equipmentMaintain = [
  { value: null, display: "select", packageKey: "cleaning"},
  { value: "timeYr", display: "input", identifier: "full" },
  { value: null, display: "group" },
  { value: "issa", display: "input" },
  { value: "hoursYr", display: "calculation", operation: "issaHours" },
  { value: "costYr", display: "calculation", operation: "issaCost" },
];
export const cleanerMaintain = [
  {
    value: null,
    display: "product",
    packageKey: "ezCleanerDrop",
    filters: { categoryId: 4, applicationId: 27, manufacturerId:1 },
  },
  //{ value: null, display: "select", packageKey: "ezCareCleaner" },
  { value: "mpp", display: "group" },
  { value: "cost_per_gal", display: "calculation", operation: "costGal" },
  { value: "issa_coverage", display: "input" },
  { value: "galYr", display: "calculation", operation: "padsNGalsHoursEz" },
  { value: "costYr", display: "calculation", operation: "padsNGalsCostEz" },
];
export const cleanScrubPadMaintain = [
  { value: null, display: "select", packageKey: "scrubbingPadEz" },
  { value: "timeYr", display: "input", identifier: "full" },
  { value: "mpp", display: "group" },
  { value: "shot_size", display: "input" },
  { value: "padsYr", display: "calculation", operation: "padsNGalsHoursEz" },
  { value: "costYr", display: "calculation", operation: "padsNGalsCostEz" },
];
export const burnishingEquipmentMaintain = [
  { value: null, display: "select", packageKey: "burnishing" },
  { value: "timeYr", display: "input", identifier: "full" },
  { value: null, display: "normal" },
  { value: "issa", display: "input" },
  { value: "hoursYr", display: "calculation", operation: "issaHours" },
  { value: "costYr", display: "calculation", operation: "issaCost" },
];
export const burnishingPadMaintain = [
  { value: null, display: "select", packageKey: "burnishingPad" },
  { value: "timeYr", display: "input", identifier: "full"},
  { value: "mpp", display: "group" },
  { value: "shot_size", display: "input" },
  { value: "padsYr", display: "calculation", operation: "padsNGalsHoursEz" },
  { value: "costYr", display: "calculation", operation: "padsNGalsCostEz" },
];

// Remover

export const equipmentRemover = [
  { value: null, display: "select", packageKey: "cleaning" },
  { value: "timeYr", display: "input", identifier: "full" },
  { value: null, display: "group" },
  { value: "issa", display: "input" },
  { value: "hoursYr", display: "calculation", operation: "issaHours" },
  { value: "costYr", display: "calculation", operation: "issaCost" },
];
export const removerContent = [
  { value: null, display: "product", packageKey: "ezRemover" },
  { value: "mpp", display: "group" },
  { value: "cost_per_gal", display: "calculation", operation: "costGal" },
  { value: "issa_coverage", display: "input" },
  { value: "galYr", display: "calculation", operation: "padsNGalsHoursEz" },
  { value: "costYr", display: "calculation", operation: "padsNGalsCostEz" },
];
export const conditioningPolishingPadRemover = [
  { value: null, display: "select", packageKey: "conditionAndPolishPad" },
  { value: "timeYr", display: "input" },
  { value: "mpp", display: "group" },
  { value: "shot_size", display: "input" },
  { value: "padsYr", display: "calculation", operation: "padsNGalsHoursEz" },
  { value: "costYr", display: "calculation", operation: "padsNGalsCostEz" },
];

// Cleaning Procedures
export const equipmentCleaningProceduresContent = [
  { value: null, display: "select", packageKey: "cleaning" },
  { value: "timeYr", display: "input" },
  { value: null, display: "normal" },
  { value: "issa", display: "input" },
  { value: "hoursYr", display: "calculation", operation: "issaHours" },
  { value: "costYr", display: "calculation", operation: "issaCost" },
];

// export const cleanerCleaningProceduresContent = [
//   { value: null, display: "product", packageKey: "cleaner", filters: { categoryId: 1, applicationId: 1 } },
//   { value: "mpp", display: "group" },
//   { value: "cost_per_gal", display: "group" },
//   { value: "issa_coverage", display: "input" },
//   { value: "galYr", display: "calculation", operation: "padsNGalsHours" },
//   { value: "costYr", display: "calculation", operation: "padsNGalsCost" },
// ];
export const cleanerCleaningProceduresContent = [
  {
    value: null,
    display: "product",
    packageKey: "cleaner",
    filters: { categoryId: 1, applicationId: 1 },
  },
  { value: "mpp", display: "group" },
  { value: "cost_per_gal", display: "calculation", operation: "costGal" },
  { value: "issa_coverage", display: "input" },
  { value: "galYr", display: "calculation", operation: "padsNGalsHours" },
  { value: "costYr", display: "calculation", operation: "padsNGalsCost" },
];

export const cleaningPadCleaningProceduresContent = [
  { value: null, display: "select", packageKey: "cleaningPad" },
  { value: "timeYr", display: "input" },
  { value: "mpp", display: "group" },
  { value: "shot_size", display: "input" },
  { value: "padsYr", display: "calculation", operation: "padsNGalsHoursEz" },
  { value: "costYr", display: "calculation", operation: "padsNGalsCostEz" },
];

// Burnishing
export const equipmentBurnishingContent = [
  { value: null, display: "select", packageKey: "burnishing" },
  { value: "timeYr", display: "input", identifier: "full" },
  { value: null, display: "normal" },
  { value: "issa", display: "input" },
  { value: "hoursYr", display: "calculation", operation: "issaHours" },
  { value: "costYr", display: "calculation", operation: "issaCost" },
];
export const burnishingPadBurnishingContent = [
  { value: null, display: "select", packageKey: "burnishingPad" },
  { value: "timeYr", display: "normal" },
  { value: "mpp", display: "group" },
  { value: "issa_coverage", display: "input" },
  { value: "padsYr", display: "calculation", operation: "padsNGalsHoursEz" },
  { value: "costYr", display: "calculation", operation: "padsNGalsCostEz" },
];

// Scrub & Recoat
export const equipmentScrubNRecoatContent = [
  { value: null, display: "select", packageKey: "scrubbing" },
  { value: "timeYr", display: "input", identifier: "full" },
  { value: null, display: "normal" },
  { value: "issa", display: "input" },
  { value: "hoursYr", display: "calculation", operation: "issaHours" },
  { value: "costYr", display: "calculation", operation: "issaCost" },
];
export const cleanerScrubNRecoatContent = [
  {
    value: null,
    display: "product",
    packageKey: "cleaner",
    filters: { categoryId: 1, applicationId: 1 },
  },
  { value: "mpp", display: "group" },
  { value: "cost_per_gal", display: "calculation", operation: "costGal" },
  { value: "issa_coverage", display: "input" },
  { value: "galYr", display: "calculation", operation: "padsNGalsHours" },
  { value: "costYr", display: "calculation", operation: "padsNGalsCost" },
];
export const scrubbingPadScrubNRecoatContent = [
  { value: null, display: "select", packageKey: "scrubbingPad" },
  { value: "timeYr", display: "normal" },
  { value: "mpp", display: "group" },
  { value: "shot_size", display: "input" },
  { value: "padsYr", display: "calculation", operation: "padsNGalsHoursEz" },
  { value: "costYr", display: "calculation", operation: "padsNGalsCostEz" },
];
export const floorFinishScrubNRecoatContent = [
  {
    value: null,
    display: "product",
    packageKey: "finish",
    filters: { categoryId: 1, applicationId: 4 },
  },
  { value: "mpp", display: "group" },
  { value: "cost_per_gal", display: "calculation", operation: "costGal" },
  { value: "issa_coverage", display: "input" },
  { value: "galYr", display: "calculation", operation: "padsNGalsHours" },
  { value: "costYr", display: "calculation", operation: "padsNGalsCost" },
];
export const applicationMethodScrubNRecoatContent = [
  { value: null, display: "select", packageKey: "conventionalRecoat" },
  { value: "timeYr", display: "normal" },
  { value: "coats", display: "input" },
  { value: "issa", display: "input" },
  { value: "hoursYr", display: "calculation", operation: "issaHours" },
  { value: "costYr", display: "calculation", operation: "issaCost" },
];

// Full-Strip Out & Finish Application
export const equipmentFullStripFinishAppContent = [
  { value: null, display: "select", packageKey: "conventionalFloorStripping" },
  { value: "timeYr", display: "input", identifier: "full" },
  { value: null, display: "input" },
  { value: "issa", display: "input" },
  { value: "hoursYr", display: "calculation", operation: "issaHours" },
  { value: "costYr", display: "calculation", operation: "issaCost" },
];
export const stripperFullStripFinishAppContent = [
  {
    value: null,
    display: "product",
    packageKey: "stripper",
    filters: { categoryId: 1, applicationId: 3 },
  },
  { value: "mpp", display: "group" },
  { value: "cost_per_gal", display: "calculation", operation: "costGal" },
  { value: "issa_coverage", display: "input" },
  { value: "padsYr", display: "calculation", operation: "padsNGalsHours" },
  { value: "costYr", display: "calculation", operation: "padsNGalsCost" },
];
export const strippingPadFullStripFinishAppContent = [
  { value: null, display: "select", packageKey: "strippingPad" },
  { value: "timeYr", display: "normal", identifier: "full" },
  { value: "mpp", display: "group" },
  { value: "shot_size", display: "input" },
  { value: "padsYr", display: "calculation", operation: "padsNGalsHoursEz" },
  { value: "costYr", display: "calculation", operation: "padsNGalsCostEz" },
];
// export const finishFullStripFinishAppContent = [
//   { value: null, display: "product", packageKey: "finish" },
//   { value: "mpp", display: "group" },
//   { value: "cost_per_gal", display: "calculation", operation: "costGal" },
//   { value: "issa_coverage", display: "input" },
//   {
//     value: "padsYr",
//     display: "calculation",
//     operation: "padsNGalsHours",
//     ctx: 1,
//     result: (val, row) =>
//       row.original[0]?.coats ? row.original[0].coats * val : val,
//   },
//   {
//     value: "costYr",
//     display: "calculation",
//     operation: "padsNGalsCost",
//     ctx: 1,
//     result: (row, hourlyWage, cleanableSqOfFacility, current) => {
//       const { hours } = hoursAndCostWithGalsNPads({
//         ...current,
//         hourlyWage,
//         cleanableSqOfFacility,
//       });

//       const calcHour = row.original[0]?.coats
//         ? row.original[0].coats * hours
//         : hours;
//       return calcHour * current?.mpp;
//     },
//   },
// ];
export const finishFullStripFinishAppContent = [
  { value: null, display: "product", packageKey: "finish" },
  { value: "mpp", display: "group" },
  { value: "cost_per_gal", display: "calculation", operation: "costGal" },
  { value: "issa_coverage", display: "input" },
  
  { value: "padsYr", display: "calculation", operation: "padsNGalsHours" },
  { value: "costYr", display: "calculation", operation: "padsNGalsCost" },
];
export const applicationMethodFullStripFinishAppContent = [
  {
    value: null,
    display: "select",
    packageKey: "conventionalFloorFinish",
    filters: { categoryId: 1, applicationId: 4 },
  },
  { value: "timeYr", display: "normal", identifier: "full" },
  { value: "coats", display: "input" },
  { value: "issa", display: "input" },
  { value: "hoursYr", display: "calculation", operation: "issaHours" },
  { value: "costYr", display: "calculation", operation: "issaCost" },
];

//  ------------- SCJP ----------------- //

// Cleaning Procedures
export const equipmentCleaningProceduresSCJPContent = [
  { value: null, display: "select", packageKey: "cleaning" },
  { value: "timeYr", display: "input" },
  { value: null, display: "normal" },
  { value: "issa", display: "input" },
  { value: "hoursYr", display: "calculation", operation: "issaHours" },
  { value: "costYr", display: "calculation", operation: "issaCost" },
];
export const cleanerCleaningProceduresSCJPContent = [
  {
    value: null,
    display: "product",
    packageKey: "cleanerScjp",
    filters: { categoryId: 1, applicationId: 3, manufacturerId: 1 },
  },
  { value: "mpp", display: "group" },
  { value: "cost_per_gal", display: "calculation", operation: "costGal" },
  { value: "issa_coverage", display: "input" },
  { value: "galYr", display: "calculation", operation: "padsNGalsHours" },
  { value: "costYr", display: "calculation", operation: "padsNGalsCost" },
];
export const cleaningPadCleaningProceduresSCJPContent = [
  { value: null, display: "select", packageKey: "cleaningPadScj" },
  { value: "timeYr", display: "input" },
  { value: "mpp", display: "group" },
  { value: "shot_size", display: "input" },
  { value: "padsYr", display: "calculation", operation: "padsNGalsHoursEz" },
  { value: "costYr", display: "calculation", operation: "padsNGalsCostEz" },
];

// Burnishing
export const equipmentBurnishingSCJPContent = [
  { value: null, display: "select", packageKey: "burnishing" },
  { value: "timeYr", display: "input", identifier: "full" },
  { value: null, display: "normal" },
  { value: "issa", display: "input" },
  { value: "hoursYr", display: "calculation", operation: "issaHours" },
  { value: "costYr", display: "calculation", operation: "issaCost" },
];
export const burnishingPadBurnishingSCJPContent = [
  { value: null, display: "select", packageKey: "burnishingPad" },
  { value: "timeYr", display: "normal" },
  { value: "mpp", display: "group" },
  { value: "issa_coverage", display: "input" },
  { value: "padsYr", display: "calculation", operation: "padsNGalsHoursEz" },
  { value: "costYr", display: "calculation", operation: "padsNGalsCostEz" },
];

// Scrub & Recoat
export const equipmentScrubSCJPNRecoatContent = [
  { value: null, display: "select", packageKey: "scrubbing" },
  { value: "timeYr", display: "input", identifier: "full" },
  { value: null, display: "normal" },
  { value: "issa", display: "input" },
  { value: "hoursYr", display: "calculation", operation: "issaHours" },
  { value: "costYr", display: "calculation", operation: "issaCost" },
];
export const cleanerScrubSCJPNRecoatContent = [
  {
    value: null,
    display: "product",
    packageKey: "cleanerScjp",
    filters: { categoryId: 1, applicationId: 3, manufacturerId: 1 },
  },
  { value: "mpp", display: "group" },
  { value: "cost_per_gal", display: "calculation", operation: "costGal" },
  { value: "issa_coverage", display: "input" },
  { value: "galYr", display: "calculation", operation: "padsNGalsHours" },
  { value: "costYr", display: "calculation", operation: "padsNGalsCost" },
];
export const scrubbingPadScrubSCJPNRecoatContent = [
  { value: null, display: "select", packageKey: "scrubbingPad" },
  { value: "timeYr", display: "normal" },
  { value: "mpp", display: "group" },
  { value: "shot_size", display: "input" },
  { value: "padsYr", display: "calculation", operation: "padsNGalsHoursEz" },
  { value: "costYr", display: "calculation", operation: "padsNGalsCostEz" },
];
export const floorFinishScrubSCJPNRecoatContent = [
  {
    value: null,
    display: "product",
    packageKey: "finishScjp",
    filters: { categoryId: 1, applicationId: 4, manufacturerId: 1 },
  },
  { value: "mpp", display: "group" },
  { value: "cost_per_gal", display: "calculation", operation: "costGal" },
  { value: "issa_coverage", display: "input" },
  { value: "galYr", display: "calculation", operation: "padsNGalsHours" },
  { value: "costYr", display: "calculation", operation: "padsNGalsCost" },
];
export const applicationMethodScrubSCJPNRecoatContent = [
  { value: null, display: "select", packageKey: "conventionalRecoat" },
  { value: "timeYr", display: "normal" },
  { value: "coats", display: "input" },
  { value: "issa", display: "input" },
  { value: "hoursYr", display: "calculation", operation: "issaHours" },
  { value: "costYr", display: "calculation", operation: "issaCost" },
];

// Full-Strip Out & Finish Application
export const equipmentFullStripFinishAppSCJPContent = [
  { value: null, display: "select", packageKey: "conventionalFloorStripping" },
  { value: "timeYr", display: "input" },
  { value: null, display: "normal" },
  { value: "issa", display: "input" },
  { value: "hoursYr", display: "calculation", operation: "issaHours" },
  { value: "costYr", display: "calculation", operation: "issaCost" },
];
export const stripperFullStripFinishAppSCJPContent = [
  {
    value: null,
    display: "product",
    packageKey: "stripperScjp",
    filters: { categoryId: 1, applicationId: 3, manufacturerId: 1 },
  },
  { value: "mpp", display: "group" },
  { value: "cost_per_gal", display: "calculation", operation: "costGal" },
  { value: "issa_coverage", display: "input" },
  { value: "padsYr", display: "calculation", operation: "padsNGalsHours" },
  { value: "costYr", display: "calculation", operation: "padsNGalsCost" },
];
export const strippingPadFullStripFinishAppSCJPContent = [
  { value: null, display: "select", packageKey: "strippingPad" },
  { value: "timeYr", display: "normal" },
  { value: "mpp", display: "group" },
  { value: "shot_size", display: "input" },
  { value: "padsYr", display: "calculation", operation: "padsNGalsHours" },
  { value: "costYr", display: "calculation", operation: "padsNGalsCost" },
];
export const applicationMethodFullStripFinishAppSCJPContent = [
  { value: null, display: "select", packageKey: "conventionalFloorFinish" },
  { value: "timeYr", display: "normal" },
  { value: "coats", display: "input" },
  { value: "issa", display: "input" },
  { value: "hoursYr", display: "calculation", operation: "issaHours" },
  { value: "costYr", display: "calculation", operation: "issaCost" },
];

function ensureNumber(value, catchValue = 0) {
  if (value === null || value === undefined) return catchValue;
  const newValue = Number(value);
  return isNaN(newValue) ? catchValue : newValue;
}

// Hours and Cost Calculation
export const hoursAndCostWithIssa = (params) => {
  const timeYr = ensureNumber(params.timeYr, 365);
  const cleanableSqOfFacility = ensureNumber(params.cleanableSqOfFacility);
  const issa = ensureNumber(params.issa, 1);
  const hourlyWage = ensureNumber(params.hourlyWage || 1);
  const coats = ensureNumber(params.coats || 1);

  const timePerSqft = Number(timeYr) * Number(cleanableSqOfFacility);
  const issaCalc = Number(issa) * 60;
  const hours = parseFloat(((timePerSqft * coats) / issaCalc).toFixed(2));
  const cost = parseFloat((hours * hourlyWage).toFixed(2));
  return { hours, cost };
};

// Cost Gal
export const costGal = (params) => {
  // const unitOfDelivery = params.unit_of_delivery
  // const totalVolumePerCase = ensureNumber(params.total_volume_per_case)

  const unitSize = ensureNumber(params.unit_size, 128);
  const unitCase = ensureNumber(params.unit_case, 4);
  const dilutionRatio = ensureNumber(params.dilution_ratio_x, 1);
  const gallonsCase = ensureNumber(
    unitSize * unitCase * ((dilutionRatio + 1) / 128),
    1
  );
  const costPerGalon = parseFloat(
    ensureNumber(params.mpp / gallonsCase, 1)
  ).toFixed(2);

  // if (unitOfDelivery === "fl oz"){costPerGalon =  parseFloat((mpp * totalVolumePerCase * 0.0078125).toFixed(2))}
  // else if (unitOfDelivery === "gal"){costPerGalon = parseFloat((mpp * totalVolumePerCase).toFixed(2))}
  // else if (unitOfDelivery === "L"){costPerGalon = parseFloat((mpp * totalVolumePerCase * 0.264172).toFixed(2))}
  // else if (unitOfDelivery === "mL"){costPerGalon = parseFloat((mpp * totalVolumePerCase * 0.000264172).toFixed(2))}

  return costPerGalon;
};

export const hoursAndCostWithCoats = (params) => {
  const timeYr = ensureNumber(params.timeYr, 365);
  const cleanableSqOfFacility = ensureNumber(params.cleanableSqOfFacility);
  const issa = ensureNumber(params.issa, 1);
  const hourlyWage = ensureNumber(params.hourlyWage);
  const coats = ensureNumber(params.coats, 1);

  const timePerSqft = timeYr * cleanableSqOfFacility * coats;
  const issaCalc = issa * 60;
  const hours = parseFloat((timePerSqft / issaCalc).toFixed(2));
  const cost = parseFloat(hours * hourlyWage).toFixed(2);
  return { hours, cost };
};

export const hoursAndCostWithGalsNPads = (params) => {
  const timeYr = ensureNumber(params.timeYr, 365);
  const cleanableSqOfFacility = ensureNumber(params.cleanableSqOfFacility);
  const mpp = ensureNumber(params.mpp, 1);

  const cost_per_gal = ensureNumber(costGal(params, 1));

  const dr = ensureNumber(params.dilution_ratio_x, 1);

  const timePerSqft = timeYr * cleanableSqOfFacility;
  const issaCoverage = ensureNumber(params.issa_coverage);
  // const unformatMpp = numbro.unformat(mpp || 1);
  //const hours = parseFloat(timePerSqft / (issaCoverage * dr)).toFixed(2);
  const hours = Number((timeYr * cleanableSqOfFacility) / issaCoverage).toFixed(
    2
  );

  const cost = cost_per_gal
    ? ensureNumber(Number(hours * cost_per_gal).toFixed(2), 1)
    : Number(hours * mpp).toFixed(2);

  if (params.log) {
    console.log(cost_per_gal);
  }

  return { hours, cost };
};

export const hoursAndCostWithGalsNPadsEz = (params) => {
  const timeYr = ensureNumber(params.timeYr, 365);
  const cleanableSqOfFacility = ensureNumber(params.cleanableSqOfFacility);
  const sqftPerPad = ensureNumber(params.shot_size, 1);

  const mpp = ensureNumber(params.mpp, 1);

  const cost_per_gal = ensureNumber(costGal(params, 1));

  const dr = ensureNumber(params.dilution_ratio_x, 1);

  const timePerSqft = timeYr * cleanableSqOfFacility;
  const issaCoverage = ensureNumber(params.issa_coverage);
  // const hours = Number((timeYr * cleanableSqOfFacility)/issaCoverage).toFixed(2);
  // const cost = cost_per_gal ? ensureNumber(Number(hours * cost_per_gal).toFixed(2), 1): Number(hours * mpp).toFixed(2) ;

  const hours = ((timeYr * cleanableSqOfFacility) / sqftPerPad).toFixed(2);
  const cost = (hours * mpp).toFixed(2);

  return { hours, cost };
};

// export const costGalByYr = (params) => {
//   const timeYr = ensureNumber(params.timeYr, 365);
//   const cleanableSqOfFacility = ensureNumber(params.cleanableSqOfFacility);
//   const mpp = ensureNumber(params.mpp, 1);
//   const cost_per_gal = ensureNumber(params.cost_per_gal, 1);

//   const timePerSqft = timeYr * cleanableSqOfFacility;
//   const issaCoverage = ensureNumber(params.issa_coverage);
//   // const unformatMpp = numbro.unformat(mpp || 1);
//   const hours = parseFloat((timePerSqft / issaCoverage).toFixed(2));
//   const cost = parseFloat(hours * cost_per_gal).toFixed(2);
//   return { hours, cost };
// };

export const calculateTotals = (
  datatable,
  hourlyWage,
  cleanableSqOfFacility
) => {
  let blueTotal = 0;
  let greenTotal = 0;
  datatable.map((i) =>
    i.data.map(({ original, headers, content }) =>
      original.map((rawData) =>
        content.map((values) =>
          values.map((c, cIndex) => {
            if (rawData?.product?.id) {
              const issaCalculations = hoursAndCostWithIssa({
                ...rawData,
                hourlyWage,
                cleanableSqOfFacility,
              });
              const coatsCalculations = hoursAndCostWithCoats({
                ...rawData,
                hourlyWage,
                cleanableSqOfFacility,
              });
              const padsCalculations = hoursAndCostWithGalsNPads({
                ...rawData,
                hourlyWage,
                cleanableSqOfFacility,
              });
              if (headers[cIndex]?.color === "blue") {
                c.operation === "issaCost" &&
                  (blueTotal += Number(issaCalculations.cost || 0));
                c.operation === "coatsCost" &&
                  (blueTotal += Number(coatsCalculations.cost || 0));
                c.operation === "padsNGalsCost" &&
                  (blueTotal += Number(padsCalculations.cost || 0));
              } else if (headers[cIndex]?.color === "green") {
                c.operation === "issaCost" &&
                  (greenTotal += Number(issaCalculations.cost || 0));
                c.operation === "coatsCost" &&
                  (greenTotal += Number(coatsCalculations.cost || 0));
                c.operation === "padsNGalsCost" &&
                  (greenTotal += Number(padsCalculations.cost || 0));
              }
            }
          })
        )
      )
    )
  );

  return { blueTotal, greenTotal, total: blueTotal + greenTotal };
};
