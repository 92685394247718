import {
  burnishingEquipmentMaintain,
  burnishingPadMaintain,
  cleanerMaintain,
  cleanScrubPadMaintain,
  equipmentMaintain,
} from "../contents";
import {
  burnishingEquipmentHeader,
  burnishingHeader,
  cleanerHeader,
  cleanScrubPadHeader,
  equipmentHeader,
} from "../../utils";

export const createEzMaintainData = (elements) => {
  const maintainOnly = elements.map((elm) => {
    const {
      ezMaintainEquipment,
      ezMaintainCleaner,
      ezMaintainClean,
      ezMaintainBurnishingEquipment,
      ezMaintainBurnishingPad,
    } = elm;
    return {
      ezMaintainEquipment,
      ezMaintainCleaner,
      ezMaintainClean,
      ezMaintainBurnishingEquipment,
      ezMaintainBurnishingPad,
    };
  });
  const equipment = maintainOnly.flatMap((i) => i.ezMaintainEquipment);
  const cleaner = maintainOnly.flatMap((i) => i.ezMaintainCleaner);
  const clean = maintainOnly.flatMap((i) => i.ezMaintainClean);
  const burnishing = maintainOnly.flatMap(
    (i) => i.ezMaintainBurnishingEquipment
  );
  const pad = maintainOnly.flatMap((i) => i.ezMaintainBurnishingPad);
  const data = [
    {
      headers: equipmentHeader,
      variable: "ezMaintainEquipment",
      original: equipment,
      content: equipment.map((i) => equipmentMaintain),
      fullData: [equipment, cleaner, clean].flat().filter(Boolean),
    },
    {
      headers: cleanerHeader,
      variable: "ezMaintainCleaner",
      original: cleaner,
      content: cleaner.map((i) => cleanerMaintain),
    },
    {
      headers: cleanScrubPadHeader,
      variable: "ezMaintainClean",
      original: clean,
      content: clean.map((i) => cleanScrubPadMaintain),
    },
    {
      headers: burnishingEquipmentHeader,
      variable: "ezMaintainBurnishingEquipment",
      original: burnishing,
      content: burnishing.map((i) => burnishingEquipmentMaintain),
      fullData: [burnishing, pad].flat().filter(Boolean),
    },
    {
      headers: burnishingHeader,
      variable: "ezMaintainBurnishingPad",
      original: pad,
      content: pad.map((i) => burnishingPadMaintain),
    },
  ];
  return data;
};
