import { cleanerHeader, cleaningHeader, equipmentHeader } from "../../utils";
import { cleanerCleaningProceduresSCJPContent, cleaningPadCleaningProceduresSCJPContent, equipmentCleaningProceduresSCJPContent } from "../contents";

export const createMultiCleaningProceduresData = (elements) => {
  const cleaningProceduresOnly = elements.map((elm) => {
    const {
      cleaningProceduresCleaner,
      cleaningProceduresCleanerScj,
      cleaningProceduresEquipment,
      cleaningProceduresEquipmentScj,
      cleaningProceduresPad,
      cleaningProceduresPadScj,
    } = elm;
    return {
      cleaningProceduresCleaner,
      cleaningProceduresCleanerScj,
      cleaningProceduresEquipment,
      cleaningProceduresEquipmentScj,
      cleaningProceduresPad,
      cleaningProceduresPadScj,
    };
  });
  const equipment = cleaningProceduresOnly.flatMap((i) => i.cleaningProceduresEquipment);
  const cleaner = cleaningProceduresOnly.flatMap((i) => i.cleaningProceduresCleaner);
  const pad = cleaningProceduresOnly.flatMap((i) => i.cleaningProceduresPad);
  const data = [
    {
      headers: equipmentHeader,
      variable: "cleaningProceduresCleaner",
      original: equipment,
      content: equipment.map((i) => equipmentCleaningProceduresSCJPContent),
      equipmentData: cleaner,
    },
    {
      headers: cleanerHeader,
      variable: "cleaningProceduresEquipment",
      original: cleaner,
      content: cleaner.map((i) => cleanerCleaningProceduresSCJPContent),
      canIgnoreIssaCoverage: true,
    },
    {
      headers: cleaningHeader,
      variable: "cleaningProceduresPad",
      original: pad,
      content: pad.map((i) => cleaningPadCleaningProceduresSCJPContent),
    },
  ];
  return data;
};
