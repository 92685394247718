import { conditioningPolishingPadRemover, equipmentRemover, removerContent } from "../contents";
import { conditionalPolishingHeader, equipmentHeader, removerHeader } from "../../utils";

export const createEzRemoverData = (elements) => {
  const strippingOutOnly = elements.map((elm) => {
    const { ezRemoveEquipment, ezRemoveRemover, ezRemoveConditioning } = elm;
    return {
      ezRemoveEquipment,
      ezRemoveRemover,
      ezRemoveConditioning,
    };
  });
  const equipment = strippingOutOnly.flatMap((i) => i.ezRemoveEquipment);
  const remover = strippingOutOnly.flatMap((i) => i.ezRemoveRemover);
  const conditioning = strippingOutOnly.flatMap((i) => i.ezRemoveConditioning);
  const data = [
    {
      headers: equipmentHeader,
      variable: "ezRemoveEquipment",
      original: equipment,
      content: equipment.map((i) => equipmentRemover),
    },
    {
      headers: removerHeader,
      variable: "ezRemoveRemover",
      original: remover,
      content: remover.map((i) => removerContent),
    },
    {
      headers: conditionalPolishingHeader,
      variable: "ezRemoveConditioning",
      original: conditioning,
      content: conditioning.map((i) => conditioningPolishingPadRemover),
    },
  ];
  return data;
};
