import {
  conditioninNPolisingPadInstallStrip,
  equipmentInstallStrip,
  stripperFullStripFinishAppContent,
  strippingPadInstallStrip,
} from "../contents";
import {
  conditionalPolishingHeader,
  equipmentHeader,
  ezInstallStripOutEquipmentHeader,
  ezInstallStripOutHeader,
  ezInstallStripOutStrippingHeader,
  installStripHeader,
  stripperHeader,
  strippingHeader,
} from "../../utils";

export const createEzStripOutFinishData = (elements) => {
  const strippingOutOnly = elements?.map((elm) => {
    const { ezInstallStripExistingFinish, ezInstallStripStripper, ezInstallStripEquipment, ezInstallStripStrippingPad, ezInstallStripConditioning } =
      elm;
    return {
      ezInstallStripExistingFinish,
      ezInstallStripStripper,
      ezInstallStripEquipment,
      ezInstallStripStrippingPad,
      ezInstallStripConditioning,
    };
  });

  const existing = strippingOutOnly?.flatMap((i) => i.ezInstallStripExistingFinish);
  const stripper = strippingOutOnly?.flatMap((i) => i.ezInstallStripStripper);
  const equipment = strippingOutOnly?.flatMap((i) => i.ezInstallStripEquipment);
  const pad = strippingOutOnly?.flatMap((i) => i.ezInstallStripStrippingPad);
  const conditioning = strippingOutOnly?.flatMap((i) => i.ezInstallStripConditioning);

  const data = [
    {
      headers: ezInstallStripOutHeader,
      variable: "ezInstallStripStripper",
      original: stripper,
      content: stripper?.map((i) => stripperFullStripFinishAppContent),
    },
    {
      headers: ezInstallStripOutEquipmentHeader,
      variable: "ezInstallStripEquipment",
      original: equipment,
      content: equipment?.map((i) => equipmentInstallStrip),
    },
    {
      headers: ezInstallStripOutStrippingHeader,
      variable: "ezInstallStripStrippingPad",
      original: pad,
      content: pad?.map((i) => strippingPadInstallStrip),
    },
    {
      headers: conditionalPolishingHeader,
      variable: "ezInstallStripConditioning",
      original: conditioning,
      content: conditioning?.map((i) => conditioninNPolisingPadInstallStrip),
    },
  ];
  return data;
};
