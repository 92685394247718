import { burnishingHeader, equipmentHeader } from "../../utils";
import {
  burnishingPadBurnishingContent,
  burnishingPadBurnishingSCJPContent,
  equipmentBurnishingContent,
  equipmentBurnishingSCJPContent,
} from "../contents";

export const createMultiBurnishingsData = (elements, currentElements) => {
  const burnishingOnly = elements.map((elm) => {
    const {
      burnishingEquipment,
      burnishingEquipmentScj,
      burnishingPad,
      burnishingPadScj,
    } = elm;
    return {
      burnishingEquipment,
      burnishingEquipmentScj,
      burnishingPad,
      burnishingPadScj,
    };
  });
  const equipment = burnishingOnly.flatMap((i) => i.burnishingEquipment);
  const pad = burnishingOnly.flatMap((i) => i.burnishingPad);
  const data = [
    {
      headers: equipmentHeader,
      variable: "burnishingEquipment",
      original: equipment,
      content: equipment.map((i) => equipmentBurnishingSCJPContent),
      equipmentData: pad,
      fullData: [equipment, pad, currentElements?.[0].fullData]
        .flat()
        .filter(Boolean),
    },
    {
      headers: burnishingHeader,
      variable: "burnishingPad",
      original: pad,
      content: pad.map((i) => burnishingPadBurnishingSCJPContent),
      canIgnoreIssaCoverage: true,
    },
  ];
  return data;
};
