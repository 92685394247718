import { toast } from "react-toastify";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import requestAuthApi from "../../common/requestAuthApi";
import CookieHandler from "../../common/cookieHandler";

const initialState = {
  user: {},
  logged: null,
  loading: true,
  fetchMeLoading: true,
  err: null,
  loadingForgotPassword: false,
};

export const login = createAsyncThunk("auth/login", async (data) => {
  try {
    const {
      data: { id_token: token, refresh_token, userId },
    } = await requestAuthApi.post(
      `/signin?Password=${encodeURIComponent(
        data.password
      )}&EmailAddress=${encodeURIComponent(data.email)}`
    );
    CookieHandler.setCookies([
      { name: "token", value: token },
      { name: "refreshToken", value: refresh_token },
      { name: "userId", value: userId },
    ]);
    const { data: user } = await requestAuthApi.get(`/api/v1/user/${userId}`);
    CookieHandler.setCookie("partnerId", user.partnerId);
    // dispatch({ type: LOGIN_SUCCESS, user });
    // return dispatch(fetchMe());
    return { logged: true, user, loading: false };
  } catch (err) {
    CookieHandler.deleteCookies([
      { name: "token" },
      { name: "refreshToken" },
      { name: "userId" },
    ]);
    toast.error("Error: Email or Password is incorrect.");
    //
    // dispatch({ type: LOGIN_FAILURE, err });
    return { err };
  }
});

export const logout = createAsyncThunk("auth/logout", async () => {
  try {
    CookieHandler.deleteCookie("token");
    CookieHandler.deleteCookie("refreshToken");
    CookieHandler.deleteCookie("userId");
    CookieHandler.deleteCookie("partnerId");
    return { logged: false, user: {}, loading: false };
  } catch (err) {
    return { err };
  }
});

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (data) => {
    try {
      const email = data.email;
      const { data: response } = await requestAuthApi.post(
        `/ChangePassword?email=${email}`
      );
      toast.success("Email sent successfully.");
      return { response };
    } catch (err) {
      return { err };
    }
  }
);

export const fetchMe = createAsyncThunk("auth/fetchMe", async (payload) => {
  try {
    const userId = CookieHandler.getCookie("userId");
    if (userId) {
      const { data: user } = await requestAuthApi.get(`/api/v1/user/${userId}`);
      // if (user.role === 'Super Admin') {
      //   user.permissions.push({ name: 'Super Admin' });
      // }
      // if (user.role === 'Administrator') {
      //   user.permissions.push({ name: 'View Subscription Information' });
      // }
      // if (user.demoUser) {
      //   user.permissions = [{ name: 'demoUser' }];
      // }

      if (payload?.cb) {
        window.requestAnimationFrame(() => {
          payload.cb(user);
        });
      }

      return { logged: true, user, fetchMeLoading: false };
    } else {
      if (payload?.cb) {
        window.requestAnimationFrame(() => {
          payload.cb(null);
        });
      }
      return { logged: false, user: {}, fetchMeLoading: false };
    }
  } catch (err) {
    if (payload?.cb) {
      window.requestAnimationFrame(() => {
        payload.cb(null);
      });
    }
    return { logged: false, user: {}, fetchMeLoading: false };
  }
});

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  // reducers: {
  //   logout: (state, action) => {
  //     Object.assign(state, initialState);
  //   },
  // },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMe.fulfilled, (state, action) => {
        Object.assign(state, action.payload);
      })
      .addCase(login.fulfilled, (state, action) => {
        Object.assign(state, action.payload);
      })
      .addCase(logout.fulfilled, (state, action) => {
        Object.assign(state, action.payload);
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        Object.assign(state, { loadingForgotPassword: false });
      })
      .addCase(forgotPassword.pending, (state, action) => {
        Object.assign(state, { loadingForgotPassword: true });
      });
    // .addCase(getManufacturer.fulfilled, (state, action) => {
    //   state.manufacturers = action.payload.data;
    // })
    // .addCase(getApplications.fulfilled, (state, action) => {
    //   state.applications = action.payload.data;
    // })
    // .addCase(
    //   getProductsByManufacturerAndApplication.fulfilled,
    //   (state, action) => {
    //     state.products = action.payload.data;
    //   },
    // );
  },
});

// export const { logout } =
//   authSlice.actions;

export default authSlice.reducer;
