import {
  applicationHeader,
  finishHeader,
  fullStripOutEquipmentHeader,
  fullStripOutFinishHeader,
  fullStripOutStripperHeader,
  strippingHeader,
} from "../../utils";
import {
  applicationMethodFullStripFinishAppContent,
  equipmentFullStripFinishAppContent,
  finishFullStripFinishAppContent,
  stripperFullStripFinishAppContent,
  strippingPadFullStripFinishAppContent,
} from "../contents";

export const createStripOutAppData = (elements, fullDataMulti) => {

  const fullStripOnly = elements?.map((elm) => {
    const {
      fullStripEquipment,
      fullStripEquipmentScj,
      fullStripFinish,
      fullStripFinishScj,
      fullStripMethod,
      fullStripMethodScj,
      fullStripPad,
      fullStripPadScj,
      fullStripStripper,
      fullStripStripperScj,
    } = elm;
    return {
      fullStripEquipment,
      fullStripEquipmentScj,
      fullStripFinish,
      fullStripFinishScj,
      fullStripMethod,
      fullStripMethodScj,
      fullStripPad,
      fullStripPadScj,
      fullStripStripper,
      fullStripStripperScj,
    };
  });
  const equipment = fullStripOnly?.flatMap((i) => i.fullStripEquipment);
  const method = fullStripOnly?.flatMap((i) => i.fullStripMethod);
  const finish = fullStripOnly?.flatMap((i) => i.fullStripFinish);
  const stripper = fullStripOnly?.flatMap((i) => i.fullStripStripper);
  const pad = fullStripOnly?.flatMap((i) => i.fullStripPad);
  const data = [
    {
      headers: fullStripOutEquipmentHeader,
      variable: "fullStripEquipment",
      original: equipment,
      content: equipment?.map((i) => equipmentFullStripFinishAppContent),
      fullData: [equipment,method,finish, stripper, pad, fullDataMulti?.[0].fullData].flat().filter(Boolean),
      equipmentData: stripper,
    },
    {
      headers: fullStripOutStripperHeader,
      variable: "fullStripStripper",
      original: stripper,
      content: stripper?.map((i) => stripperFullStripFinishAppContent),
      canIgnoreIssaCoverage: true,
    },
    {
      headers: strippingHeader,
      variable: "fullStripPad",
      original: pad,
      content: pad?.map((i) => strippingPadFullStripFinishAppContent),
    },
    {
      headers: fullStripOutFinishHeader,
      variable: "fullStripFinish",
      original: finish,
      content: finish?.map((i) => finishFullStripFinishAppContent),
    },
    {
      headers: applicationHeader,
      variable: "fullStripMethod",
      original: method,
      content: method?.map((i) => applicationMethodFullStripFinishAppContent),
    },
  ];
  return data;
};
