import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { http } from "../../common/http.common";
import { createQuote, getQuote, reloadQuote } from "./calculator.store";

const initialState = {
  applications: [],
  reloading: false,
  loading: true,
  products: [],
  packages: [],
  manufacturers: [],
  order: [],
  manufacturer: "",
  product: "",
  cost_hour: 0,
  comparing: false,
  scjpProducts: [],
  selectProducts: [],
  created: null,
  quote: {
    product: false,
    company: "",
    project: "",
    skin: false,
    surface: false,
    floor: false,
    estimation: false,
    competitive: true,
    labor: false,
    comments: "",
  },
  rawQuote: {},
  tabs: [
    {
      name: "overview",
      show: true,
    },
    {
      name: "skin-care",
      show: false,
    },
    {
      name: "surface-care",
      show: false,
    },
    {
      name: "floor-care",
      show: false,
    },
    {
      name: "labor-calculation",
      show: false,
    },
    {
      name: "summary-report",
      show: true,
    },
  ],
};

export const getApplications = createAsyncThunk("application/getApplication", async () => {
  try {
    const { data } = await http.get("/application");

    return data;
  } catch (error) {
    return {};
  }
});

export const getPackages = createAsyncThunk("package/getPackages", async (params) => {
  try {
    const {
      data: { data: packages },
    } = await http.get("/packages", { params });
    return packages.map((i) => ({ ...i, mpp: i.mpp.replace("$", "") }));
  } catch (error) {
    return {};
  }
});

export const getScjpPackages = createAsyncThunk("package/getScjpPackages", async (params) => {
  try {
    const {
      data: { data: packages },
    } = await http.get("/packages", { params });
    return packages.map((i) => ({ ...i, mpp: i.mpp.replace("$", "") }));
  } catch (error) {
    return {};
  }
});

export const getManufacturer = createAsyncThunk("manufacturer/getManufacturer", async (params) => {
  try {
    const { data } = await http.get("/manufacturers", { params });
    return data;
  } catch (error) {
    return {};
  }
});

export const getProducts = createAsyncThunk("product/getProducts", async (params) => {
  try {
    const { data } = await http.get("/products", { params });
    return data;
  } catch (error) {
    return {};
  }
});

export const getProductsByManufacturerAndApplication = createAsyncThunk("product/getProducts", async ({ manufacturer, application, category }) => {
  try {
    const { data } = await http.get(`/product/manufacturer-application?manufacturer=${manufacturer}&application=${application}&category=${category}`);

    return data;
  } catch (error) {
    return {};
  }
});

export const newQuotesSlice = createSlice({
  name: "newQuote",
  initialState: initialState,
  reducers: {
    handleGetPackages: (state, action) => {
      state.packages = action.payload;
    },
    handleSetProduct: (state, action) => {
      state.product = action.payload;
    },
    handleSetManufacturer: (state, action) => {
      state.manufacturer = action.payload;
    },
    handleAddProductToOrder: (state, action) => {
      state.order.push(...state.selectProducts);
    },
    cleanQuote: (state) => {
      state.quote = initialState.quote;
      state.tabs = initialState.tabs;
      state.created = null;
    },
    handleAddSelectProduct: (state, action) => {
      state.selectProducts = action.payload;
    },
    handleActiveTab: (state, { payload }) => {
      state.tabs = state.tabs.map((elm) => (elm.name === payload.name ? { ...elm, show: payload.state } : elm));
    },
    handleSetCompany: (state, { payload }) => {
      state.company = payload;
    },
    handleSetProject: (state, { payload }) => {
      state.project = payload;
    },
    handleSetCostHours: (state, { payload }) => {
      state.cost_hour = payload;
    },
    handleSetComparing: (state, { payload }) => {
      state.comparing = payload;
    },
    handleSetComments: (state, { payload }) => {
      state.comments = payload;
    },
    handleChangeQuote: (state, { payload }) => {
      state.quote = { ...state.quote, ...payload };
    },
    handleSetCostCase: (state, { payload }) => {
      state.order = state.order.map((elm) => (elm.product_code === payload.product_code ? { ...elm, case_cost: payload.case_cost } : elm));
    },
    setPackages: (state, { payload }) => {
      state.packages = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPackages.fulfilled, (state, action) => {
        state.packages = action.payload;
      })
      .addCase(getScjpPackages.fulfilled, (state, action) => {
        state.scjpProducts = action.payload;
      })
      .addCase(getManufacturer.fulfilled, (state, action) => {
        state.manufacturers = action.payload.data;
      })
      .addCase(getApplications.fulfilled, (state, action) => {
        state.applications = action.payload.data;
      })
      .addCase(getProductsByManufacturerAndApplication.fulfilled, (state, action) => {
        state.products = action.payload.data;
      })
      .addCase(getQuote.pending, (state) => {
        state.loading = true;
      })
      .addCase(reloadQuote.fulfilled, (state, { payload }) => {
        state.reloading = true;
        state.quote.company = payload.company_name;
        state.quote.project = payload.project_name;
        state.quote.product = payload.product_mix_in_use_cost_calculation;
        state.quote.skin = payload.include_skin;
        state.quote.surface = payload.include_surface;
        state.quote.floor = payload.include_floor;
        state.quote.estimation = payload.lead_value_estimation;
        state.quote.competitive = payload.compare_competitive_products;
        state.quote.labor = payload.labor_chemicals_cost_calculation;
        state.tabs = state.tabs.map((elm) => {
          if (elm.name === "surface-care") elm.show = payload.include_surface;
          if (elm.name === "floor-care") elm.show = payload.include_floor;
          if (elm.name === "skin-care") elm.show = payload.include_skin;
          if (elm.name === "labor-calculation") elm.show = payload.labor_chemicals_cost_calculation;
          return elm;
        });
        state.quote.customerChallenge = payload.customerChallenge;
        state.quote.industry = payload.industry;
        state.rawQuote = payload;
      })
      .addCase(getQuote.rejected, (state) => {
        state.loading = true;
        state.reloading = false;
      })
      .addCase(getQuote.fulfilled, (state, { payload }) => {
        state.reloading = false;
        state.quote.company = payload.company_name;
        state.quote.comments = payload.comments;
        state.quote.project = payload.project_name;
        state.quote.product = payload.product_mix_in_use_cost_calculation;
        state.quote.skin = payload.include_skin;
        state.quote.surface = payload.include_surface;
        state.quote.floor = payload.include_floor;
        state.quote.estimation = payload.lead_value_estimation;
        state.quote.competitive = payload.compare_competitive_products;
        state.quote.labor = payload.labor_chemicals_cost_calculation;
        state.loading = false;
        state.tabs = state.tabs.map((elm) => {
          if (elm.name === "surface-care") elm.show = payload.include_surface;
          if (elm.name === "floor-care") elm.show = payload.include_floor;
          if (elm.name === "skin-care") elm.show = payload.include_skin;
          if (elm.name === "labor-calculation") elm.show = payload.labor_chemicals_cost_calculation;
          return elm;
        });
        state.rawQuote = payload;
        state.quote.customerChallenge = payload.customerChallenge;
        state.quote.industry = payload.industry;
      })
      .addCase(createQuote.fulfilled, (state, { payload }) => {
        state.created = { ...payload };
      });
  },
});

export const {
  handleGetPackages,
  handleSetProduct,
  handleSetManufacturer,
  handleAddProductToOrder,
  handleAddSelectProduct,
  handleActiveTab,
  handleSetCompany,
  handleSetProject,
  handleSetCostHours,
  handleSetComparing,
  handleSetComments,
  cleanQuote,
  handleChangeQuote,
  setPackages,
  handleSetCostCase,
} = newQuotesSlice.actions;

export default newQuotesSlice.reducer;
