// ** Reducers Imports
import layout from "./layout";
import navbar from "./navbar";
import newQuotes from "./stores/newQuotes.store";
import calculator from "./stores/calculator.store";
import packages from "./stores/packages.store";
import auth from "./stores/auth.store";

const rootReducer = { navbar, layout, newQuotes, auth, packages, calculator };

export default rootReducer;
