import { cleanerHeader, cleaningHeader, equipmentHeader } from "../../utils";
import { cleanerCleaningProceduresContent, cleaningPadCleaningProceduresContent, equipmentCleaningProceduresContent } from "../contents";

export const createCleaningProceduresData = (elements) => {
  const cleaningProceduresOnly = elements?.map((elm) => {
    const {
      cleaningProceduresCleaner,
      cleaningProceduresCleanerScj,
      cleaningProceduresEquipment,
      cleaningProceduresEquipmentScj,
      cleaningProceduresPad,
      cleaningProceduresPadScj,
    } = elm;
    return {
      cleaningProceduresCleaner,
      cleaningProceduresCleanerScj,
      cleaningProceduresEquipment,
      cleaningProceduresEquipmentScj,
      cleaningProceduresPad,
      cleaningProceduresPadScj,
    };
  });

  const equipment = cleaningProceduresOnly?.flatMap((i) => i.cleaningProceduresEquipment);
  const cleaner = cleaningProceduresOnly?.flatMap((i) => i.cleaningProceduresCleaner);
  const pad = cleaningProceduresOnly?.flatMap((i) => i.cleaningProceduresPad);

  return [
    {
      headers: equipmentHeader,
      variable: "cleaningProceduresEquipment",
      original: equipment,
      content: equipment?.map(() => equipmentCleaningProceduresContent),
      equipmentData: cleaner,
    },
    {
      headers: cleanerHeader,
      variable: "cleaningProceduresCleaner",
      original: cleaner,
      content: cleaner?.map(() => cleanerCleaningProceduresContent),
      canIgnoreIssaCoverage: true,
    },
    {
      headers: cleaningHeader,
      variable: "cleaningProceduresPad",
      original: pad,
      content: pad?.map(() => cleaningPadCleaningProceduresContent),
    },
  ];
};
