import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { http } from "../../common/http.common";

const initialState = {
  packages: [],
  categories: [],
  manufacturers: [],
  products: [],
  applications: [],
  laborPackages: {
    cleaner: [],
    cleanerScjp: [],
    stripper: [],
    stripperScjp: [],
    finish: [],
    finishScjp: [],
    various: {},
    ezCleanerDrop: [],
    ezCareFloorC:[],
    ezRemover:[]
  },
};

export const getPackages = createAsyncThunk("packages/getPackages", async (payload) => {
  try {
    const {
      data: { data: packages },
    } = await http.get(`/packages`, { params: { ...payload } });
    return packages.map((i) => ({ ...i, mpp: i.mpp.replace("$", "") }));
  } catch (err) {
    return [];
  }
});

const formatVarious = (arr, appId) => arr.map((i) => ({ ...i, mpp: i.mpp.replace("$", "") })).filter(({ applicationId }) => applicationId === appId)
  .sort((a, b) => a.product_name.trim() > b.product_name.trim() ? 1 : -1);
const formarVariousArr = (arr, appIds) => appIds.map((i) => formatVarious(arr, i)).flat()
  .sort((a, b) => a.product_name.trim() > b.product_name.trim() ? 1 : -1);

export const getPackagesLabor = createAsyncThunk("packages/getPackagesLabor", async (payload = {}) => {
  try {

    const promises = [
      http.get("/packages", { params: { categoryId: 1, applicationId: 1, ...payload } }),
      http.get("/packages", { params: { categoryId: 1, applicationId: 1, manufacturerId: 1, ...payload } }),
      http.get("/packages", { params: { categoryId: 1, applicationId: 3, ...payload } }),
      http.get("/packages", { params: { categoryId: 1, applicationId: 3, manufacturerId: 1, ...payload } }),
      http.get("/packages", { params: { categoryId: 1, applicationId: 4, ...payload } }),
      http.get("/packages", { params: { categoryId: 1, applicationId: 4, manufacturerId: 1 } }),
      http.get("/packages", { params: { categoryId: 4, ...payload } }),
      http.get("/packages", { params: { categoryId: 4, applicationId: 27, manufacturerId: 1} }),
      http.get("/packages", { params: { categoryId: 4, applicationId: 29, manufacturerId: 1} }),
      http.get("/packages", { params: { categoryId: 4, applicationId: 30, manufacturerId: 1} }),
    ];

    const [
      {
        data: { data: cleaner },
      },
      {
        data: { data: cleanerScjp },
      },
      {
        data: { data: stripper },
      },
      {
        data: { data: stripperScjp },
      },
      {
        data: { data: finish },
      },
      {
        data: { data: finishScjp },
      },
      {
        data: { data: various },
      },
      {
        data: { data: ezCleanerDrop },
      },
      {
        data: { data: ezCareFloorC },
      },
      {
        data: { data: ezRemover },
      },
    ] = await Promise.all(promises);

    const dolarRemoved = various.map((i) => ({ ...i, mpp: i.mpp.replace("$", "") }));
    return {
      cleaner: cleaner.map((i) => ({ ...i, mpp: i.mpp.replace("$", "") })),
      cleanerScjp: cleanerScjp.map((i) => ({ ...i, mpp: i.mpp.replace("$", "") })),
      stripper: stripper.map((i) => ({ ...i, mpp: i.mpp.replace("$", "") })),
      stripperScjp: stripperScjp.map((i) => ({ ...i, mpp: i.mpp.replace("$", "") })),
      finish: finish.map((i) => ({ ...i, mpp: i.mpp.replace("$", "") })),
      finishScjp: finishScjp.map((i) => ({ ...i, mpp: i.mpp.replace("$", "") })),
      ezCleanerDrop: ezCleanerDrop.map((i) => ({ ...i, mpp: i.mpp.replace("$", "") })),
      ezCareFloorC: ezCareFloorC.map((i) => ({ ...i, mpp: i.mpp.replace("$", "") })),
      ezRemover: ezRemover.map((i) => ({ ...i, mpp: i.mpp.replace("$", "") })),
      various: {
        cleaner: formatVarious(dolarRemoved, 1),
        burnishing: formatVarious(dolarRemoved, 19),
        burnishingPad: formatVarious(dolarRemoved, 20),
        cleanShinePad: formatVarious(dolarRemoved, 21),
        cleaning: formatVarious(dolarRemoved, 22),
        cleaningPad: formatVarious(dolarRemoved, 23),
        cleaningPadScj: formarVariousArr(dolarRemoved, [23, 31]),
        conventionalFloorFinish: formatVarious(dolarRemoved, 24),
        conventionalFloorStripping: formatVarious(dolarRemoved, 25),
        conventionalRecoat: formatVarious(dolarRemoved, 26),
        conditionAndPolishPad: formatVarious(dolarRemoved, 28),
        floorCoating: formatVarious(dolarRemoved, 29),
        floorCoatingRemover: formatVarious(dolarRemoved, 30),
        scrubbing: formatVarious(dolarRemoved, 33),
        scrubbingPad: formatVarious(dolarRemoved, 34),
        scrubbingPadEz: formarVariousArr(dolarRemoved, [34, 31]),
        strippingPad: formatVarious(dolarRemoved, 36),
        ezCareCleaner: formatVarious(dolarRemoved, 27),
      },
    };
  } catch (err) {
    return [];
  }
});

const extractData = (request) => request.data.data;

export const loadFilters = createAsyncThunk("packages/loadFilters", async (payload) => {
  try {

    const promises = [
      http.get("/categories").then(extractData),
      http.get(`/products?userId=${payload.userId}`).then(extractData),
      http.get("/applications").then(extractData),
      http.get("/manufacturers").then(extractData),
    ];

    const [categories, products, applications, manufacturers] = await Promise.all(promises);

    return { categories, applications, manufacturers, products };
  } catch (error) {
    return error;
  }
});

export const authSlice = createSlice({
  name: "packages",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getPackages.fulfilled, (state, { payload }) => void (state.packages = payload))
      .addCase(loadFilters.fulfilled, (state, { payload }) => ({ ...state, ...payload }))
      .addCase(getPackagesLabor.fulfilled, (state, { payload }) => void (state.laborPackages = payload));
  },
});

// export const { logout } =
//   authSlice.actions;

export default authSlice.reducer;
