import axios from "axios";
import cookies from "./cookieHandler";

const authUrl = process.env.REACT_APP_AUTH_API_URL;
const calculatorUrl = process.env.REACT_APP_CALCULATOR_API;

const requestAuthApi = axios.create({ baseURL: `${authUrl}` });
export const calculatorApi = axios.create({ baseURL: `${calculatorUrl}` });

requestAuthApi.interceptors.request.use(
  (config) => {
    const token = cookies.getCookie("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

requestAuthApi.interceptors.response.use(
  (response) => response,
  function (error) {
    const originalRequest = error.config;
    if (error.response)
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        return requestAuthApi
          .post("/RefreshToken", {
            refreshToken: cookies.getCookie("refreshToken"),
          })
          .then((res) => {
            if (res.status === 200) {
              // 1) put token to LocalStorage
              cookies.setCookie("refreshToken", res.data.refresh_token);
              cookies.setCookie("token", res.data.id_token);

              // 2) Change Authorization header
              requestAuthApi.defaults.headers.common["Authorization"] = `Bearer ${res.data.id_token}`;

              // 3) return originalRequest object with Axios.
              return requestAuthApi(originalRequest);
            }
          })
          .catch((err) => {
            return originalRequest;
          });
      }

    // return Error object with Promise
    return Promise.reject(error);
  }
);

export default requestAuthApi;
