import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { http } from "../../common/http.common";
import {
  formatAddFloorCareData,
  formatHandleAddFloorCare,
  formatHandleAddSkinCare,
  formatLaborChemicalTable,
  formatPackagesFromApi,
  resetAllCases,
  setActiveLink,
} from "../utils";

const initialState = {
  quotes: [],
  skinCareProducts: [],
  surfaceCareProducts: [],
  floorCareProducts: [],
  laborData: { id: "", hourlyWage: "", cleanableSqOfFacility: "", currentFloor: [], multiFloor: [], EzCareFloor: [] },
  tabSelected: "multi",
  loading: false,
};

export const editElements = createAsyncThunk("calculator/editElements", async (data) => {
  try {
    await http.patch(`/quote-packages/${data.id}`, data);

    return {};
  } catch (error) {
    return {};
  }
});

export const saveSkinCareProducts = createAsyncThunk("calculator/saveSkinCareProducts", async (data) => {
  try {
    const response = await http.post("/skin-quotes", data);
    return response.data;
  } catch (error) {
    return [];
  }
});

export const saveSurfaceCareProducts = createAsyncThunk("calculator/saveSurfaceCareProducts", async (data) => {
  try {
    const response = await http.post("/surface-quotes", data);
    return response.data;
  } catch (error) {
    return [];
  }
});

export const saveLaborCare = createAsyncThunk("calculator/saveLaborCare", async (data) => {
  try {
    const response = await http.post("/surface-quotes", data);
    f;
    return response.data;
  } catch (error) {
    return [];
  }
});

export const saveFloorCareProducts = createAsyncThunk("calculator/saveFloorCareProducts", async (data) => {
  try {
    const response = await http.post("/floor-quotes", data);
    return response.data;
  } catch (error) {
    return [];
  }
});

export const editFloorCareQuote = createAsyncThunk("calculator/saveFloorCareProducts", async ({ id, data }) => {
  try {
    const response = await http.patch(`/floor-care-quote/${id}`, data);
    return response.data;
  } catch (error) {
    return [];
  }
});

export const deleteProducts = createAsyncThunk("calculator/deleteProducts", async ({ type, id }) => {
  try {
    await http.delete(`/${type}-quotes/${id}`);
    return id;
  } catch (error) {
    return [];
  }
});

export const createQuote = createAsyncThunk("calculator/createQuote", async (payload) => {
  try {
    const {
      data: { data },
    } = await http.post("/quotes", payload);
    return { id: data.id, route: setActiveLink(data) };
  } catch (error) {
    return null;
  }
});

export const reloadQuote = createAsyncThunk("calculator/reloadQuote", async (id) => {
  try {
    const {
      data: { data },
    } = await http.get(`/quotes/${id}`);
    return data;
  } catch (error) {
    return {};
  }
});

export const editQuote = createAsyncThunk("calculator/editQuote", async (payload) => {
  try {
    const {
      data: { data },
    } = await http.patch(`/quotes/${payload.id}`, payload);
    return data;
  } catch (error) {
    return null;
  }
});

export const getQuote = createAsyncThunk("calculator/getQuote", async (id) => {
  try {
    const {
      data: { data },
    } = await http.get(`/quotes/${id}`);
    return data;
  } catch (error) {
    return {};
  }
});

export const getQuotes = createAsyncThunk("calculator/getAllQuotes", async (params) => {
  try {
    const {
      data: { data },
    } = await http.get("/quotes", { params });
    return data;
  } catch (error) {
    return [];
  }
});

export const calculatorSlice = createSlice({
  name: "calculator",
  initialState: initialState,
  reducers: {
    changeSurfaceCareProduct: (state, { payload }) => {
      state.surfaceCareProducts = formatHandleAddFloorCare(formatAddFloorCareData(state.surfaceCareProducts, payload));
    },
    changeSkinCareProduct: (state, { payload }) => {
      state.skinCareProducts = formatHandleAddSkinCare(formatAddFloorCareData(state.skinCareProducts, payload));
    },
    changeFloorCareProduct: (state, { payload }) => {
      state.floorCareProducts = formatHandleAddFloorCare(formatAddFloorCareData(state.floorCareProducts, payload));
    },
    editFields: (state, { payload }) => {
      const laborData = { ...state.laborData, ...payload };
      state.laborData = laborData;
    },
    handleTabChange: (state, { payload }) => {
      state.tabSelected = payload;
    },
    editTableFields: (state, { payload }) => {
      const { type, value, variable, header, index, packageValue } = payload;
      const snapshot = state.laborData[type];
      const headerIndex = snapshot.findIndex((i) => i.header === header);
      const variableIndex = snapshot[headerIndex].data.findIndex((i) => i.variable === variable);
      state.laborData[type][headerIndex].data[variableIndex].original[index] = { ...packageValue, ...value };
    },
    resetCases: (state) => {
      if (state.surfaceCareProducts) {
        state.surfaceCareProducts = resetAllCases(state.surfaceCareProducts);
      }

      if (state.skinCareProducts) {
        state.skinCareProducts = resetAllCases(state.skinCareProducts);
      }

      if (state.floorCareProducts) {
        state.floorCareProducts = resetAllCases(state.floorCareProducts);
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQuote.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getQuote.pending, (state) => {
        state.loading = true;
      })
      .addCase(getQuote.fulfilled, (state, { payload }) => {
        state.skinCareProducts = formatHandleAddSkinCare(formatPackagesFromApi(payload.skinQuotes));
        state.surfaceCareProducts = formatHandleAddFloorCare(formatPackagesFromApi(payload.SurfaceQuote));
        state.floorCareProducts = formatHandleAddFloorCare(formatPackagesFromApi(payload.floorQuotes));
        if (payload.floorCareLaborQuote) {
          state.laborData = { ...formatLaborChemicalTable(payload.floorCareLaborQuote || {}), id: payload.floorCareLaborQuote.id };
        }
        state.loading = false;
      })
      .addCase(reloadQuote.fulfilled, (state, { payload }) => {
        state.skinCareProducts = formatHandleAddSkinCare(formatPackagesFromApi(payload.skinQuotes));
        state.surfaceCareProducts = formatHandleAddFloorCare(formatPackagesFromApi(payload.SurfaceQuote));
        state.floorCareProducts = formatHandleAddFloorCare(formatPackagesFromApi(payload.floorQuotes));
        if (payload.floorCareLaborQuote) {
          state.laborData = { ...formatLaborChemicalTable(payload.floorCareLaborQuote || {}), id: payload.floorCareLaborQuote.id };
        }
      })
      .addCase(getQuotes.fulfilled, (state, { payload }) => {
        state.quotes = payload;
      })
      .addCase(deleteProducts.fulfilled, (state, { payload }) => {
        state.skinCareProducts = state.skinCareProducts.filter(({ customPackageId }) => payload !== customPackageId);
        state.surfaceCareProducts = state.surfaceCareProducts.filter(({ customPackageId }) => payload !== customPackageId);
        state.floorCareProducts = state.floorCareProducts.filter(({ customPackageId }) => payload !== customPackageId);
      })
      .addCase(saveSkinCareProducts.fulfilled, (state) => {
        state = state;
      })
      .addCase(saveSurfaceCareProducts.fulfilled, (state) => {
        state = state;
      })
      .addCase(saveFloorCareProducts.fulfilled, (state) => {
        state = state;
      });
  },
});

export const {
  deleteSurfaceCare,
  deleteSkinCare,
  deleteFloorCare,
  handleSetSkinCareProducts,
  handleSetSurfaceCareProducts,
  handleSetFloorCareProducts,
  changeSurfaceCareProduct,
  changeSkinCareProduct,
  changeFloorCareProduct,
  editFields,
  editTableFields,
  handleTabChange,
  resetCases,
} = calculatorSlice.actions;

export default calculatorSlice.reducer;
