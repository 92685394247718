import { applicationMethodFullStripFinishAppContent, applyFloorCoating, installCoatingFloorCoating } from "../contents";
import { applicationHeader, installCoatingHeader, installEzHeader } from "../../utils";

export const createEzInstallCareData = (elements) => {
  const strippingOutOnly = elements?.map((elm) => {
    const { ezInstallCareApply, ezInstallCareCoating, ezInstallCareMethod } = elm;
    return {
      ezInstallCareApply,
      ezInstallCareCoating,
      ezInstallCareMethod,
    };
  });
  const apply = strippingOutOnly?.flatMap((i) => i.ezInstallCareApply);
  const coating = strippingOutOnly?.flatMap((i) => i.ezInstallCareCoating);
  const method = strippingOutOnly?.flatMap((i) => i.ezInstallCareMethod);
  const data = [
    {
      headers: installEzHeader,
      variable: "ezInstallCareApply",
      original: apply,
      content: apply?.map((i) => applyFloorCoating),
    },
    // {
    //   headers: installCoatingHeader,
    //   variable: "ezInstallCareCoating",
    //   original: coating,
    //   content: coating?.map((i) => installCoatingFloorCoating),
    // }
    // ,
    {
      headers: applicationHeader,
      variable: "ezInstallCareMethod",
      original: method,
      content: method?.map((i) => applicationMethodFullStripFinishAppContent),
    },
  ];
  return data;
};
