import numbro from "numbro";
import { createBurnishingsData } from "./floorCareLaborChemicalUtils/current/burnishingUtils";
import { createCleaningProceduresData } from "./floorCareLaborChemicalUtils/current/cleaningProceduresUtils";
import { createScrubRecoatData } from "./floorCareLaborChemicalUtils/current/scrubNRecoatUtils";
import { createStripOutAppData } from "./floorCareLaborChemicalUtils/current/stripOutAppUtils";
import { createMultiCleaningProceduresData } from "./floorCareLaborChemicalUtils/multi/cleaningProceduresUtils";
import { createMultiBurnishingsData } from "./floorCareLaborChemicalUtils/multi/burnishingUtils";
import { createMultiScrubRecoatData } from "./floorCareLaborChemicalUtils/multi/scrubNRecoatUtils";
import { createMultiStripOutAppData } from "./floorCareLaborChemicalUtils/multi/stripOutAppUtils";
import { createEzStripOutFinishData } from "./floorCareLaborChemicalUtils/ez/ezStripOutUtils";
import { createEzInstallCareData } from "./floorCareLaborChemicalUtils/ez/ezInstallCare";
import { createEzMaintainData } from "./floorCareLaborChemicalUtils/ez/ezMaintainUtils";
import { createEzRemoverData } from "./floorCareLaborChemicalUtils/ez/ezRemoverUtils";

export const addNewKeys = (elm) => {
  const cases = Number(numbro.unformat(elm.cases || 1));
  const unit_size = Number(numbro.unformat(elm.unit_size || 1));
  const unit_case = Number(numbro.unformat(elm.unit_case || 1));
  const dilution_ratio_x = Number(numbro.unformat(elm.dilution_ratio_x || 1));
  const mpp = Number(numbro.unformat(elm.mpp || 1));
  const use_liters = cases * unit_size * unit_case * (dilution_ratio_x / 32);
  const total_cost = cases * mpp;
  const use_cost = use_liters * total_cost;
  return {
    ...elm,
    use_liters,
    total_cost,
    use_cost,
  };
};

export const formatAddFloorCareData = (data, payload) =>
  data.map((i, index) => {
    if (index === payload.index) i[payload.name] = payload.value;
    return i;
  });

export const formatHandleAddSkinCare = (data) => {
  return data.map((i) => {
    const cases = i.cases === undefined ? 1 : i.cases;
    const use_liters =
      numbro.unformat(cases) *
      numbro.unformat(i.unit_case) *
      numbro.unformat(i.unit_size) *
      ((1 + numbro.unformat(i.dilution_ratio_x)) / 128);
    const total_cost = Number(i.cases || 1) * numbro.unformat(i.mpp || 1);
    const use_cost = (
      Number(total_cost || 1) / Number(use_liters || 1)
    ).toFixed(2);

    return {
      ...i,
      cases,
      mpp: i.mpp,
      unit_size: i.unit_size,
      unit_case: i.unit_case,
      dilution_ratio_x: i.dilution_ratio_x,
      use_liters,
      total_cost,
      use_cost,
    };
  });
};

export const formatHandleAddFloorCare = (data) => {
  const parsed = data.map((i) => {
    const cases = i.cases === undefined ? 1 : i.cases;
    const use_liters =
      numbro.unformat(cases) *
      numbro.unformat(i.unit_case) *
      numbro.unformat(i.unit_size) *
      ((1 + numbro.unformat(i.dilution_ratio_x)) / 128);
    const total_cost = Number(i.cases || 1) * numbro.unformat(i.mpp || 1);
    const use_cost = Number(total_cost || 1) / Number(use_liters || 1);

    return {
      ...i,
      cases,
      mpp: i.mpp,
      unit_size: i.unit_size,
      unit_case: i.unit_case,
      dilution_ratio_x: i.dilution_ratio_x,
      use_liters,
      total_cost,
      use_cost,
    };
  });

  return parsed.map((i, index, arr) => {
    if (i.scjp) {
      const inUseGallonLiters = arr[index - 1].use_liters;
      // CASES = IN-USE GALLONS / (UNITS PER CASE * UNIT SIZE * (DILUTION RATIO + 1) / 128)
      const inUseGallonCases =
        inUseGallonLiters /
        ((i.unit_case *
          i.unit_size *
          (numbro.unformat(i.dilution_ratio_x) + 1)) /
          128);
      const inUseGallonTotalCost = numbro(
        Number(inUseGallonCases || 1) * numbro.unformat(i.mpp || 1)
      );
      const inUseGallonCost = inUseGallonTotalCost / inUseGallonLiters;

      return {
        ...i,
        inUseGallonLiters,
        inUseGallonCases,
        inUseGallonTotalCost,
        inUseGallonCost,
      };
    }
    return i;
  });
};

export const formatCaresDataArray = async (quoteId, data) => {
  let obj = { quoteId };
  let results = [];
  const justSCJP = data.filter(
    ({ scjp_product_code }) => !scjp_product_code
  ).length;
  await data.map(({ id, scjp_product_code }) => {
    if (!String(id).includes("case-variable") && !scjp_product_code) {
      obj = { ...obj, scjPackageId: id };
      if (justSCJP === data.length) results.push({ ...obj, packageId: id });
    } else if (String(id).includes("case-variable")) {
      obj = { ...obj, variance_id: id };
      results.push(obj);
      obj = { quoteId };
    } else if (scjp_product_code !== null) {
      obj = { ...obj, packageId: id };
    }
  });
  return results;
};

export const formatPackagesFromApi = (data = []) => {
  let results = [];
  data.forEach((i) => {
    const elements = [];
    elements.push({ ...i.package, customPackageId: i.id });
    i.variance_id &&
      elements.push({ ...i.scjPackage, customPackageId: i.id, scjp: true });
    elements.push({
      ...i.package,
      id: i.variance_id,
      caseVariable: true,
      customPackageId: i.id,
    });
    return results.push(...elements);
  });
  return results;
};

export const getActiveTabName = (params) => {
  const skin = JSON.parse(params.get("skin"));
  const surface = JSON.parse(params.get("surface"));
  const floor = JSON.parse(params.get("floor"));
  if (skin) return "skin-care";
  if (surface) return "surface-care";
  if (floor) return "floor-care";
  return null;
};

export const setActiveLink = (data) => {
  const {
    include_floor,
    include_skin,
    include_surface,
    labor_chemicals_cost_calculation,
  } = data;
  if (include_skin) return "skin-care";
  if (include_surface) return "surface-care";
  if (include_floor) return "floor-care";
  if (labor_chemicals_cost_calculation) return "labor-calculation";
};

export const checkTabNameCondition = (name, activeTab) => {
  if (name === activeTab) return true;
  return false;
};

export const fullStripOutEquipmentHeader = [
  { name: "Equipment & Frequency" },
  { name: "#times/yr" },
  { name: "" },
  {
    name: (
      <>
        sqft/min<sup>1</sup>
      </>
    ),
  },
  { name: "hours/yr" },
  { name: "cost/yr", color: "blue" },
];

export const equipmentHeader = [
  { name: "Equipment & Frequency" },
  { name: "#times/yr" },
  { name: "" },
  {
    name: (
      <>
        sqft/min<sup>1</sup>
      </>
    ),
  },
  { name: "hours/yr" },
  { name: "cost/yr", color: "blue" },
];

export const ezInstallStripOutEquipmentHeader = [
  { name: "Equipment & Frequency" },
  { name: "#times" },
  { name: "" },
  {
    name: (
      <>
        sqft/min<sup>1</sup>
      </>
    ),
  },
  { name: "hours/yr" },
  { name: "cost/yr", color: "blue" },
];

export const cleanerHeader = [
  { name: "Cleaner" },
  { name: "cost/case" },
  { name: "cost/gal" },
  {
    name: (
      <>
        sqft/gal<sup>1</sup>
      </>
    ),
  },
  { name: "gal/yr" },
  { name: "cost/yr", color: "green" },
];
export const cleaningHeader = [
  { name: "Cleaning Pad" },
  { name: "#times/yr" },
  { name: "cost/pad" },
  {
    name: (
      <>
        sqft/pad<sup>1</sup>
      </>
    ),
  },
  { name: "pads/yr" },
  { name: "cost/yr", color: "green" },
];

export const burnishingHeader = [
  { name: "Burnishing Pad" },
  { name: "#times/yr" },
  { name: "cost/pad" },
  {
    name: (
      <>
        sqft/pad<sup>1</sup>
      </>
    ),
  },
  { name: "pads/yr" },
  { name: "cost/yr", color: "green" },
];

export const floorHeader = [
  { name: "Floor Finish" },
  { name: "cost/case" },
  { name: "cost/gal" },
  {
    name: (
      <>
        sqft/gal<sup>1</sup>
      </>
    ),
  },
  { name: "gal/yr" },
  { name: "cost/yr", color: "green" },
];

export const scribbingHeader = [
  { name: "Burnishing Pad" },
  { name: "#times/yr" },
  { name: "cost/pad" },
  {
    name: (
      <>
        sqft/pad<sup>1</sup>
      </>
    ),
  },
  { name: "pads/yr" },
  { name: "cost/yr", color: "green" },
];

export const applicationHeader = [
  { name: "Application Method" },
  { name: "#times/yr" },
  { name: "#coats" },
  {
    name: (
      <>
        sqft/min<sup>1</sup>
      </>
    ),
  },
  { name: "hours/yr" },
  { name: "cost/yr", color: "blue" },
];

export const stripperHeader = [
  { name: "Stripper" },
  { name: "cost/case" },
  { name: "cost/gal" },
  {
    name: (
      <>
        sqft/gal<sup>1</sup>
      </>
    ),
  },
  { name: "gal/yr" },
  { name: "cost/yr", color: "green" },
];

export const ezInstallStripOutHeader = [
  { name: "Stripper" },
  { name: "cost/case" },
  { name: "cost/gal" },
  {
    name: (
      <>
        sqft/gal<sup>1</sup>
      </>
    ),
  },
  { name: "gal/yr" },
  { name: "cost/yr", color: "green" },
];

export const fullStripOutStripperHeader = [
  { name: "Stripper" },
  { name: "cost/case" },
  { name: "cost/gal" },
  {
    name: (
      <>
        sqft/gal<sup>1</sup>
      </>
    ),
  },
  { name: "gal/yr" },
  { name: "cost/yr", color: "green" },
];

export const strippingHeader = [
  { name: "Stripping Pad" },
  { name: "#times/yr" },
  { name: "cost/pad" },
  {
    name: (
      <>
        sqft/pad<sup>1</sup>
      </>
    ),
  },
  { name: "pads/yr" },
  { name: "cost/yr", color: "green" },
];

export const ezInstallStripOutStrippingHeader = [
  { name: "Stripping Pad" },
  { name: "#times/yr" },
  { name: "cost/pad" },
  {
    name: (
      <>
        sqft/pad<sup>1</sup>
      </>
    ),
  },
  { name: "pads/yr" },
  { name: "cost/yr", color: "green" },
];

export const finishHeader = [
  { name: "Finish" },
  { name: "cost/case" },
  { name: "cost/pad" },
  {
    name: (
      <>
        sqft/pad<sup>1</sup>
      </>
    ),
  },
  { name: "pads/yr" },
  { name: "cost/yr", color: "green" },
];

export const fullStripOutFinishHeader = [
  { name: "Finish" },
  { name: "cost/case" },
  { name: "cost/gal" },
  {
    name: (
      <>
        sqft/gal<sup>1</sup>
      </>
    ),
  },
  { name: "gal/yr" },
  { name: "cost/yr", color: "green" },
];

export const installStripHeader = [
  { name: "#times" },
  { name: "" },
  {
    name: (
      <>
        sqft/min<sup>1</sup>
      </>
    ),
  },
  { name: "hours/yr" },
  { name: "cost/yr", color: "blue" },
];

export const conditionalPolishingHeader = [
  { name: "Conditioning & Polishing Pad" },
  { name: "#times" },
  { name: "cost/pad" },
  {
    name: (
      <>
        sqft/pad<sup>1</sup>
      </>
    ),
  },
  { name: "pads/yr" },
  { name: "cost/yr", color: "green" },
];

export const installEzHeader = [
  { name: "Apply EZ Care Floor Coating" },
  { name: "cost/case" },
  { name: "cost/gal" },
  {
    name: (
      <>
        sqft/gal<sup>1</sup>
      </>
    ),
  },
  { name: "gal/yr" },
  { name: "cost/yr", color: "green" },
];

export const installCoatingHeader = [
  { name: "Install Coating after floor preparation" },
  { name: "#times" },
  { name: "#coats" },
  {
    name: (
      <>
        sqft/min<sup>1</sup>
      </>
    ),
  },
  { name: "hours/yr" },
  { name: "cost/yr", color: "blue" },
];

export const cleanScrubPadHeader = [
  { name: "Scrubbing Pad" },
  { name: "#times/yr" },
  { name: "cost/pad" },
  {
    name: (
      <>
        sqft/pad<sup>1</sup>
      </>
    ),
  },
  { name: "pads/yr" },
  { name: "cost/yr", color: "green" },
];

export const EzMaintainCleanScrubPadHeader = [
  { name: "Clean & Scrub Pad" },
  { name: "#times/yr" },
  { name: "cost/pad" },
  {
    name: (
      <>
        sqft/pad<sup>1</sup>
      </>
    ),
  },
  { name: "pads/yr" },
  { name: "cost/yr", color: "green" },
];

export const burnishingEquipmentHeader = [
  { name: "Burnishing Equipment & Frequency" },
  { name: "#times/yr" },
  { name: "" },
  {
    name: (
      <>
        sqft/pad<sup>1</sup>
      </>
    ),
  },
  { name: "pads/yr" },
  { name: "cost/yr", color: "blue" },
];

export const removerHeader = [
  { name: "Remover" },
  { name: "cost/case" },
  { name: "cost/gal" },
  {
    name: (
      <>
        sqft/gal<sup>1</sup>
      </>
    ),
  },
  { name: "gal/yr" },
  { name: "cost/yr", color: "green" },
];

function createMultiTable(elements, elementsCurrent) {
  return [
    {
      header: "Cleaning Procedures",
      data: createMultiCleaningProceduresData(elements),
    },
    { header: "Burnishing", data: createMultiBurnishingsData(elements, createBurnishingsData(elementsCurrent)) },
    {
      header: "Scrub & Recoat",
      data: createMultiScrubRecoatData(
        elements,
        createScrubRecoatData(elementsCurrent)
      ),
    },
    {
      header: "Full-Strip Out & Finish Application",
      data: createMultiStripOutAppData(
        elements,
        createStripOutAppData(elementsCurrent)
      ),
    },
  ];
}

function createEzTable(elements) {
  return [
    {
      header: "Initial Strip-Out of Old Finish",
      data: createEzStripOutFinishData(elements),
    },
    {
      header: "Install EZ Care Floor Coating",
      data: createEzInstallCareData(elements),
    },
    { header: "Maintain", data: createEzMaintainData(elements) },
    {
      header: "Remove EZ Care Floor Coating",
      data: createEzRemoverData(elements),
    },
  ];
}

function createSimpleTable(elements, elementsMulti) {
  return [
    {
      header: "Cleaning Procedures",
      data: createCleaningProceduresData(elements),
    },
    { header: "Burnishing", data: createBurnishingsData(elements, createMultiBurnishingsData(elementsMulti)) },
    {
      header: "Scrub & Recoat",
      data: createScrubRecoatData(
        elements,
        createMultiScrubRecoatData(elementsMulti)
      ),
    },
    {
      header: "Full-Strip Out & Finish Application",
      data: createStripOutAppData(
        elements,
        createMultiStripOutAppData(elementsMulti)
      ),
    },
  ];
}

function setDefaultValue(table) {
  for (const item of table) {
    for (const rows of item.data) {
      for (let index = 0; index < rows.original.length; index++) {
        const element = rows.original[index];
        const newElement = { ...element };
        if (newElement.issa_coverage === null && newElement.packageId) {
          newElement.issa_coverage = 999;
        }
        rows.original[index] = newElement;
      }
    }
  }
}

export const formatLaborChemicalTable = (data) => {
  const {
    cleanableSqOfFacility,
    scjpFloorCareSystemEzCare,
    scjpFloorCareSystemMultiSurface,
    hourlyWage,
    currentFloorCareSystem,
  } = data;
  const currentFloor = createSimpleTable(
    currentFloorCareSystem,
    scjpFloorCareSystemMultiSurface
  );
  const multiFloor = createMultiTable(
    scjpFloorCareSystemMultiSurface,
    currentFloorCareSystem
  );
  const EzCareFloor = createEzTable(scjpFloorCareSystemEzCare);

  setDefaultValue([...currentFloor]);
  setDefaultValue([...multiFloor]);
  // setDefaultValue(EzCareFloor);

  return {
    hourlyWage,
    cleanableSqOfFacility,
    currentFloor,
    multiFloor,
    EzCareFloor,
  };
};

export function resetAllCases(data) {
  return data.map((item) => {
    return {
      ...item,
      cases: 1,
    };
  });
}
